<template>
  <div id="App">
    <a-button
      v-if="hasAction('edit-company-info')"
      type="primary"
      icon="plus"
      @click="handleAdd()"
    >
      新增路演信息
    </a-button>
    <a-list :data-source="data" :grid="{ span: 24 }">
      <a-list-item slot="renderItem" slot-scope="record">
        <div>
          <span class="item-name">{{ record.name }}</span>
          <a v-if="hasAction('edit-company-info')" @click="handleEdit(record)">
            <a-icon type="edit"></a-icon>
          </a>
          <a-divider
            type="vertical"
            v-if="hasAction('edit-company-info')"
          /><a-popconfirm
            v-if="hasAction('edit-company-info')"
            title="确认移除该条信息吗？"
            ok-text="是"
            cancel-text="否"
            @confirm="handleDelete(record)"
          >
            <a><a-icon type="delete"></a-icon></a>
          </a-popconfirm>
        </div>
        <div>
          <a-descriptions>
            <a-descriptions-item label="分数">
              {{ record.score }}
            </a-descriptions-item>
            <a-descriptions-item label="日期">
              {{ record.date }}
            </a-descriptions-item>
            <a-descriptions-item label="行业">
              {{ record.industry }}
            </a-descriptions-item>
            <a-descriptions-item label="信息来源">
              <a :href="record.source" target="blank">{{ record.source }}</a>
            </a-descriptions-item>
          </a-descriptions>
        </div>
      </a-list-item>
    </a-list>
  </div>
</template>

<script>
import { getRoadshowList, deleteRoadshow } from "@/api/company";
import ContentForm from "./components/ContentForm";

export default {
  data() {
    return {
      loading: false,
      data: [],
      finance: {
        dataSource: [],
        info: {
          round: "",
          investorName: "",
          moneyNum: "",
          date: "",
          realTime: "",
          financeAdvisor: "",
        },
      },
    };
  },
  created() {
    this.companyId = this.$route.params.companyId;
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      getRoadshowList({
        companyId: this.companyId,
      }).then((res) => {
        this.data = res.data;
        this.loading = false;
      });
    },
    handleAdd() {
      this.$dialog(
        ContentForm,
        {
          record: { companyId: this.companyId },
          on: {
            ok: () => {
              this.loadData();
            },
            cancel() {},
            close() {},
          },
        },
        {
          title: "新增",
          width: 700,
          height: 500,
          centered: true,
          maskClosable: false,
        }
      );
    },
    handleEdit(record) {
      this.$dialog(
        ContentForm,
        {
          record,
          on: {
            ok: () => {
              this.loadData();
            },
            cancel() {},
            close() {},
          },
        },
        {
          title: "修改",
          width: 700,
          height: 500,
          centered: true,
          maskClosable: false,
        }
      );
    },
    handleDelete(record) {
      deleteRoadshow(record).then(() => {
        this.loadData();
        this.$message.success("删除成功！");
      });
    },
  },
};
</script>

<style scoped>
.ant-list >>> .ant-list-item {
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 10px;
}
</style>
<style lang="less" scoped>
.item-name {
  font-size: 16px;
  font-weight: 700;
  line-height: 40px;
  margin-right: 10px;
}
</style>
