import request from "@/utils/request"

const companyApi = {
    GetCompanyList: '/company/search',
    GetCompanyInfo: '/company/info',
    AddOrUpdateCompany: '/company/add-or-update',
    GetCompanyName:'/company/name',

    GetTeamList: '/company/teamMember-background',
    AddTeam: '/team/add',
    UpdateTeam: '/team/edit',
    DeleteTeam: '/team/delete',
    SyncTeam: '/team/update-by-survey',

    GetFinanceList: '/company/finance-background',
    GetFinanceInfo: '/finance/info/',
    AddFinance: '/finance/add',
    CopyFinance:'/finance/copy-finance',
    UpdateFinance: '/finance/edit',
    DeleteFinance: '/finance/delete',

    GetPatentList: '/company/patent',
    GetPatentInfo: '/company/patent-count',
    GetRecruitList: '/company/recruit',
    GetChangeRecordList: '/company/change-record/',
    GetEmployeeList: '/company/employee',
    GetGovernmentRewardList: '/company/gover-reward/',
    GetLicenseList: '/company/license/',
    GetNewsList: '/company/news',
    GetNewsInfo: '/company/news-count',
    GetShareHolderList: '/company/shareholder',
    GetStandardList: '/company/standard/',

    GetContestList: '/contest/list',
    AddContest: '/contest/add',
    UpdateContest: '/contest/edit',
    DeleteContest: '/contest/delete',

    GetExhibitionList: '/exhibition/list',
    AddExhibition: '/exhibition/add',
    UpdateExhibition: '/exhibition/edit',
    DeleteExhibition: '/exhibition/delete',

    GetTopListData: '/top-list/list',
    AddTopList: '/top-list/add',
    UpdateTopList: '/top-list/edit',
    DeleteTopList: '/top-list/delete',

    GetVenturecampList: '/venturecamp/list',
    AddVenturecamp: '/venturecamp/add',
    UpdateVenturecamp: '/venturecamp/edit',
    DeleteVenturecamp: '/venturecamp/delete',

    GetRoadshowList: '/roadshow/list',
    AddRoadshow: '/roadshow/add',
    UpdateRoadshow: '/roadshow/edit',
    DeleteRoadshow: '/roadshow/delete',

    UpdateTeamFromQMP: '/data/team/update',
    UpdateFinanceFromQMP:'/data/finance/update',

    SearchCompany: '/company/search-company-name'
}

export function getCompanyList(parameter) {
    return request({
        url: companyApi.GetCompanyList,
        method: 'get',
        params: parameter
    })
}

export function getCompanyInfo(parameter) {
    return request({
        url: companyApi.GetCompanyInfo + `/${parameter.companyId}`,
        method: 'get',
    })
}

export function addOrUpdateCompany(parameter) {
    return request({
        url: companyApi.AddOrUpdateCompany,
        method: 'post',
        data: parameter
    })
}

export function getTeamList(parameter) {
    return request({
        url: companyApi.GetTeamList + `/${parameter.companyId}`,
        method: 'get',
    })
}

export function addTeam(parameter) {
    return request({
        url: companyApi.AddTeam,
        method: 'post',
        data: parameter
    })
}

export function updateTeam(parameter) {
    return request({
        url: companyApi.UpdateTeam,
        method: 'post',
        data: parameter
    })
}

export function deleteTeam(parameter) {
    return request({
        url: companyApi.DeleteTeam + `/${parameter.id}`,
        method: 'post'
    })
}

export function syncTeam(parameter) {
    return request({
        url: companyApi.SyncTeam,
        method: 'post',
        data: parameter
    })
}

export function getFinanceList(parameter) {
    return request({
        url: companyApi.GetFinanceList + `/${parameter.companyId}`,
        method: 'get',
    })
}

export function getFinanceInfo(parameter) {
    return request({
        url: companyApi.GetFinanceInfo + `/${parameter.id}`,
        method: 'get',
    })
}

export function addFinance(parameter) {
    return request({
        url: companyApi.AddFinance,
        method: 'post',
        data: parameter
    })
}

export function copyFinance(parameter) {
    return request({
        url: companyApi.CopyFinance,
        method: 'post',
        data: parameter
    })
}

export function updateFinance(parameter) {
    return request({
        url: companyApi.UpdateFinance,
        method: 'post',
        data: parameter
    })
}

export function deleteFinance(parameter) {
    return request({
        url: companyApi.DeleteFinance + `/${parameter.id}`,
        method: 'post'
    })
}

export function getPatentList(parameter) {
    return request({
        url: companyApi.GetPatentList + `/${parameter.companyId}`,
        method: 'get',
        params: {
            page:parameter.page,
            pageSize: parameter.pageSize,
        }
    })
}

export function getPatentInfo(parameter) {
    return request({
        url: companyApi.GetPatentInfo + `/${parameter.companyId}`,
        method: 'get',
    })
}

export function getRecruitList(parameter) {
    return request({
        url: companyApi.GetRecruitList + `/${parameter.companyId}`,
        method: 'get',
        params: {
            page: parameter.page,
            pageSize: parameter.pageSize,
        }
    })
}

export function getChangeRecordList(parameter) {
    return request({
        url: companyApi.GetChangeRecordList + `/${parameter.companyId}`,
        method: 'get',
    })
}

export function getEmployeeList(parameter) {
    return request({
        url: companyApi.GetEmployeeList + `/${parameter.companyId}`,
        method: 'get',
    })
}

export function getGovernmentRewardList(parameter) {
    return request({
        url: companyApi.GetGovernmentRewardList + `/${parameter.companyId}`,
        method: 'get',
    })
}

export function getLicenseList(parameter) {
    return request({
        url: companyApi.GetLicenseList + `/${parameter.companyId}`,
        method: 'get',
    })
}

export function getNewsList(parameter) {
    return request({
        url: companyApi.GetNewsList + `/${parameter.companyId}`,
        method: 'get',
        params: {
            page: parameter.page,
            pageSize: parameter.pageSize,
        }
    })
}

export function getNewsInfo(parameter) {
    return request({
        url: companyApi.GetNewsInfo + `/${parameter.companyId}`,
        method: 'get',
    })
}

export function getShareHolderList(parameter) {
    return request({
        url: companyApi.GetShareHolderList + `/${parameter.companyId}`,
        method: 'get',
    })
}

export function getStandardList(parameter) {
    return request({
        url: companyApi.GetStandardList + `/${parameter.companyId}`,
        method: 'get',
        params: {
            page: parameter.page,
            pageSize: parameter.pageSize,
        }
    })
}

export function getContestList(parameter) {
    return request({
        url: companyApi.GetContestList + `?companyId=${parameter.companyId}`,
        method: 'get',
    })
}

export function addContest(parameter) {
    return request({
        url: companyApi.AddContest,
        method: 'post',
        data: parameter
    })
}


export function updateContest(parameter) {
    return request({
        url: companyApi.UpdateContest,
        method: 'post',
        data: parameter
    })
}

export function deleteContest(parameter) {
    return request({
        url: companyApi.DeleteContest + `/${parameter.id}`,
        method: 'post',
    })
}

export function getExhibitionList(parameter) {
    return request({
        url: companyApi.GetExhibitionList + `?companyId=${parameter.companyId}`,
        method: 'get',
    })
}

export function addExhibition(parameter) {
    return request({
        url: companyApi.AddExhibition,
        method: 'post',
        data: parameter
    })
}


export function updateExhibition(parameter) {
    return request({
        url: companyApi.UpdateExhibition,
        method: 'post',
        data: parameter
    })
}

export function deleteExhibition(parameter) {
    return request({
        url: companyApi.DeleteExhibition + `/${parameter.id}`,
        method: 'post',
    })
}

export function getTopListData(parameter) {
    return request({
        url: companyApi.GetTopListData + `?companyId=${parameter.companyId}`,
        method: 'get',
    })
}

export function addTopList(parameter) {
    return request({
        url: companyApi.AddTopList,
        method: 'post',
        data: parameter
    })
}


export function updateTopList(parameter) {
    return request({
        url: companyApi.UpdateTopList,
        method: 'post',
        data: parameter
    })
}

export function deleteTopList(parameter) {
    return request({
        url: companyApi.DeleteTopList + `/${parameter.id}`,
        method: 'post',
    })
}

export function getVenturecampList(parameter) {
    return request({
        url: companyApi.GetVenturecampList + `?companyId=${parameter.companyId}`,
        method: 'get',
    })
}

export function addVenturecamp(parameter) {
    return request({
        url: companyApi.AddVenturecamp,
        method: 'post',
        data: parameter
    })
}


export function updateVenturecamp(parameter) {
    return request({
        url: companyApi.UpdateVenturecamp,
        method: 'post',
        data: parameter
    })
}

export function deleteVenturecamp(parameter) {
    return request({
        url: companyApi.DeleteVenturecamp + `/${parameter.id}`,
        method: 'post',
    })
}

export function getRoadshowList(parameter) {
    return request({
        url: companyApi.GetRoadshowList + `?companyId=${parameter.companyId}`,
        method: 'get',
    })
}

export function addRoadshow(parameter) {
    return request({
        url: companyApi.AddRoadshow,
        method: 'post',
        data: parameter
    })
}


export function updateRoadshow(parameter) {
    return request({
        url: companyApi.UpdateRoadshow,
        method: 'post',
        data: parameter
    })
}

export function deleteRoadshow(parameter) {
    return request({
        url: companyApi.DeleteRoadshow + `/${parameter.id}`,
        method: 'post',
    })
}

export function getCompanyName(parameter) {
    return request({
        url: companyApi.GetCompanyName,
        method: 'get',
        params:parameter
    })
}

export function updateTeamFromQMP(parameter) {
    return request({
        url: companyApi.UpdateTeamFromQMP + `/${parameter.companyId}`,
        method: 'post'
    })
}

export function updateFinanceFromQMP(parameter) {
    return request({
        url: companyApi.UpdateFinanceFromQMP + `/${parameter.companyId}`,
        method: 'post'
    })
}

export function searchCompany(parameter) {
    return request({
        url: companyApi.SearchCompany,
        method: 'get',
        params: parameter
    })
}