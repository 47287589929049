<template>
  <a-form :form="form">
    <a-form-item label="名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        placeholder="请输入路演名称"
        v-decorator="['name', { initialValue: record.name }]"
      />
    </a-form-item>
    <a-form-item label="分数" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        placeholder="请输入分数"
        type="number"
        v-decorator="['score', { initialValue: record.score }]"
      />
    </a-form-item>
    <a-form-item label="日期" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-date-picker
        v-decorator="['date', { initialValue: record.date }]"
        placeholder="请输入日期"
      />
    </a-form-item>
    <a-form-item label="行业" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        placeholder="请输入所属行业"
        v-decorator="['industry', { initialValue: record.industry }]"
      />
    </a-form-item>
    <a-form-item label="信息来源" :labelCol="labelCol" :wrapperCol="wrapperCol">
      <a-input
        placeholder="请输入信息来源链接"
        v-decorator="['source', { initialValue: record.source }]"
      />
    </a-form-item>
  </a-form>
</template>

<script>
import { addRoadshow, updateRoadshow } from "@/api/company";
import moment from "moment";

export default {
  name: "TaskForm",
  props: {
    record: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 },
      },
      form: this.$form.createForm(this),
    };
  },
  methods: {
    onOk() {
      return new Promise((resolve) => {
        this.form.validateFields((err, values) => {
          if (!err) {
            let formData = {
              companyId: this.record.companyId,
              date: moment(values.date).utc(480),
              score: values.score,
              industry: values.industry,
              name: values.name,
              source: values.source,
            };
            if (this.record.id) {
              // 更新
              let data = {
                id: this.record.id,
                ...formData,
              };
              updateRoadshow(data).then(() => {
                this.$message.success("更新成功");
                resolve(true);
              });
            } else {
              // 创建
              addRoadshow(formData).then(() => {
                this.$message.success("添加成功！");
                resolve(true);
              });
            }
          }
        });
      });
    },
    onCancel() {
      return new Promise((resolve) => {
        resolve(true);
      });
    },
  },
};
</script>

<style scoped>
/* tile uploaded pictures */
.upload-list-inline >>> .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline >>> .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline >>> .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}
</style>
